import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/arrow-left.svg";
import { ReactComponent as Arrow } from "../assets/icons/Arrow.svg";
import { ReactComponent as FaceScan } from "../assets/icons/face-scan.svg";
import { ReactComponent as FingerScan } from "../assets/icons/finger-scan.svg";

const SelectScanType = () => {
  const { state } = useLocation();

  const nextRoute = useMemo(() => {
    if (
      state.posture?.length > 0 &&
      /^(resting|standing|walking|exercising)$/.test(state.posture)
    )
      return "/scan-instructions";
    else return "/select-posture";
  }, [state.posture]);

  return (
    <section className="min-h-screen px-5 pt-16 pb-32">
      <div className="fixed top-0 left-0 right-0 flex items-center space-x-2 px-4 py-3 border-b border-[#D6D9DA] bg-white text-[#0D212C]">
        <Link
          className="shrink-0 h-6 w-6"
          to="/basic-details"
          state={{ ...state, scan_type: undefined }}
        >
          <BackArrow className="h-full w-full" />
        </Link>
        <h2 className="text-sm font-semibold">Wellness</h2>
      </div>

      <h3 className="mt-2 text-[#0D212C] text-base font-semibold">
        Select Scan Type
      </h3>

      <div className="mt-6 space-y-3">
        <Link
          className="p-4 rounded-lg flex space-x-4 bg-white shadow-sm"
          to={nextRoute}
          state={{ ...state, scan_type: "face" }}
        >
          <FaceScan className="shrink-0 w-16 h-16 rounded-lg bg-[#F9FAFB]" />
          <div className="grow">
            <h4 className="text-[#0D212C] text-base font-bold">Face Scan</h4>
            <p className=" text-[#6E7A80] text-xs">
              60-seconds contactless vital scan using your smartphone's front
              camera. It is as simple as taking a selfie.
            </p>
            <div className="inline-flex items-center space-x-1 rounded mt-2 px-1 py-2 bg-[#F4FFFD] text-[#3ABBC7]">
              <span className="flex-shrink-0 text-xs font-semibold">
                Start Scan
              </span>
              <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
            </div>
          </div>
        </Link>
        <Link
          className="p-4 rounded-lg flex space-x-4 bg-white shadow-sm"
          to={nextRoute}
          state={{ ...state, scan_type: "finger" }}
        >
          <FingerScan className="shrink-0 w-16 h-16 rounded-lg bg-[#F9FAFB]" />

          <div className="grow">
            <h4 className="text-[#0D212C] text-base font-bold">Finger Scan</h4>
            <p className=" text-[#6E7A80] text-xs">
              Place your finger on the rear camera of your smartphone to scan
              your vital signs in 60 seconds.
            </p>
            <div className="inline-flex items-center space-x-1 rounded mt-2 px-1 py-2 bg-[#F4FFFD] text-[#3ABBC7]">
              <span className="flex-shrink-0 text-xs font-semibold">
                Start Scan
              </span>
              <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
            </div>
          </div>
        </Link>
      </div>

      <div className="mt-3 p-4 rounded-lg bg-[#FFFDE7] border border-[#FCE484]">
        <h3 className="text-base font-semibold text-[#3E4E56]">Disclaimer</h3>
        <p className="mt-2 text-xs text-left text-[#3E4E56]">
          For Investigational Use Only. This scan is not a substitute for the
          clinical judgment of a healthcare professional. This scan is intended
          to improve your awareness of general wellness. This scan does not
          diagnose, treat, mitigate or prevent any disease, symptom, disorder or
          abnormal physical state. Consult with a healthcare professional or
          emergency services if you believe you may have a medical issue.
        </p>
      </div>
    </section>
  );
};

export default SelectScanType;
