import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { fingerScan } from "careplix-web-algo";

import LoadingScreen from "../components/LoadingScreen";

import Failure from "../assets/images/failure.gif";
import HertBeat from "../assets/images/heartbeat.gif";
import Analyzing from "../assets/images/analyzing.gif";
import { ReactComponent as WifiSignal } from "../assets/icons/wifi-square.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as RightArrow } from "../assets/icons/right-arrow.svg";

const scanMessages = [
  "We burn calories while we are asleep because brain activity requires energy",
  "Exercise promotes cardiovascular health, ensuring more blood and oxygen to circulate the body, helping to elevate energy",
  "Always sleep on your back straight since it allows your neck and spine in a neutral position",
  "Alcohol consumption disrupts chemical impulses between brain cells causing impulsive behaviour, slurred speech, impaired memory",
  "Meditation increases nitric oxide generation in the brain helping to lower your blood pressure",
  "Meditation reduces the production of cytokines in several recent studies, thus helping in stress reduction",
  "Laughing is good for your heart. It reduces stress and gives a boost to your immune system",
  "Lack of sleep can lead to heart disease, heart attack, or stroke",
  "Exercise boosts brain performance",
  "Heart pumps about 2,000 gallons of blood every day",
  "Your heart will beat about 115,000 times each day",
].sort(() => Math.random() - 0.5);

const getMessage = (type = "", timeElapsed = 0) => {
  if (type !== "scan") {
    if (timeElapsed <= 5000) return "Cover your back camera with your finger";
    else if (timeElapsed <= 10000)
      return "During the measurement, please do not speak or move";
    else if (timeElapsed <= 17000)
      return "Keep your device steady throughout the vital measurement";
    else if (timeElapsed <= 18000) return "We are good to start in 3";
    else if (timeElapsed <= 19000) return "We are good to start in 2";
    else if (timeElapsed <= 20000) return "We are good to start in 1";
  } else {
    if (timeElapsed <= 30000) return "Scan in Progress...";
    else if (timeElapsed <= 40000) return scanMessages[0];
    else if (timeElapsed <= 50000) return scanMessages[1];
    else if (timeElapsed <= 60000) return scanMessages[2];
    else if (timeElapsed <= 70000) return scanMessages[3];
    else if (timeElapsed <= 80000) return scanMessages[4];
    else if (timeElapsed <= 90000) return scanMessages[5];
    else if (timeElapsed <= 100000) return scanMessages[6];
    else if (timeElapsed <= 110000) return scanMessages[7];
    else if (timeElapsed <= 120000) return scanMessages[8];
    else if (timeElapsed <= 130000) return scanMessages[9];
    else if (timeElapsed <= 140000) return scanMessages[10];
  }
};

const FingerScan = () => {
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState("");
  const [scanFrameData, setScanFrameData] = useState({
    type: "",
    timeElapsed: 0,
    confidence: 0,
    fps: 0,
  });

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state.scan_token?.length > 0) {
      fingerScan.onFrame((fd) => setScanFrameData(fd));
      fingerScan.onScanFinish(
        async ({ raw_intensity, ppg_time, average_fps }) => {
          try {
            setAnalyzing(true);
            const resp = await fetch(
              "https://sdk.uae.careplix.com/vitals/add-scan",
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  employee_id: state.partner_client_id,
                  api_key: state.partner_key,
                  scan_token: state.scan_token,
                  posture: state.posture,
                  dob: state.dob,
                  gender: state.gender,
                  metadata: {
                    physiological_scores: {
                      height: state.height,
                      weight: state.weight,
                    },
                    device: `RPPG_CAREPLIX_FINGER_${
                      navigator.platform.match(/iPhone|iPod|iPad/)
                        ? "IOS"
                        : "ANDROID"
                    }`,
                    ppg_time: ppg_time,
                    raw_intensity: raw_intensity,
                    fps: average_fps,
                  },
                }),
              }
            );
            const resp_json = await resp.json();
            if (resp_json.statusCode?.toString().startsWith("2")) {
              if (state.is_webview) {
                window.parent?.postMessage?.(
                  `scan-result:::${JSON.stringify(resp_json)}`,
                  "*"
                );
                window.ReactNativeWebView?.postMessage?.(
                  `scan-result:::${JSON.stringify(resp_json)}`
                );
              }
              if (state.show_result)
                navigate("/scan-result", {
                  state: { ...state, result: resp_json },
                  replace: true,
                });
              else
                window.location.replace(
                  new URL(
                    `${state.callback_url}${
                      state.callback_url.slice(-1) !== "/" ? "/" : ""
                    }vitals-scan-result?${new URLSearchParams({
                      data: JSON.stringify(resp_json),
                    })}`
                  )
                );
            } else throw new Error(resp_json.message);
          } catch (err) {
            console.error(err);
            setError(err.message);
          }
        }
      );
      fingerScan.onError((err) => {
        console.error(err);
        setError(err.message);
      });
      fingerScan
        .startScan(60000, 60000)
        .then(() => console.log("Scan Started"))
        .catch(console.error);
    } else setError("Invalid Scan Token");
    return () => {
      fingerScan.stopScan(true);
    };
  }, [navigate, state]);

  return (
    <div className="relative h-screen bg-black">
      {error.length > 0 ? (
        <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
          <img src={Failure} alt="failure icon" className="w-48 mx-auto" />
          <p className="mt-3 text-[#0D212C] text-center">
            Scan Failed!
            <br />
            Please try again.
          </p>
          <button
            className="mt-8 px-4 py-2 rounded-lg bg-[#F05252] text-white text-sm font-medium"
            type="button"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </button>
        </div>
      ) : analyzing ? (
        <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
          <img src={Analyzing} alt="analyzing icon" className="w-56 mx-auto" />
          <p className="mt-8 text-[#0D212C] text-base font-bold">
            Analyzing Data
          </p>
          <p className="mt-2 text-[#6E7A80] text-xs">
            Hold tight, the measurement results are
            <br />
            on the way
          </p>
        </div>
      ) : (
        <>
          <div className="relative h-full w-full">
            <video
              className="fixed bottom-12 left-8 w-px h-px bg-white/80"
              id="videoInput"
              autoPlay
              muted
              playsInline
            />
            <canvas id="canvasOutput" className="h-full w-full -scale-x-100" />
            <div className="fixed inset-x-0 top-[25vh]">
              {fingerScan.isFingerInView() ? (
                <img src={HertBeat} alt="heartbeat" className="w-48 mx-auto" />
              ) : (
                <>
                  <svg className="w-48 mx-auto" viewBox="0 0 300 90">
                    <circle fill="#fff" cx="278.63" cy="55.48" r="13.33" />
                    <polyline
                      fill="none"
                      stroke="#fff"
                      strokeWidth={5}
                      strokeMiterlimit={10}
                      points="7.75,54.04 74.69,54.04 83.58,21.09 96.13,72.86 102.93,54.04 118.61,53.51 130.12,10.11 158.36,80.71 169.86,54.04 189.73,54.04 198.1,23.18 200.71,23.18 212.22,68.68 217.45,54.04 265.57,53.96"
                    />
                  </svg>
                  <p className="mt-2 text-xl text-center text-white drop-shadow">
                    Cannot Detect Finger
                  </p>
                </>
              )}
            </div>
            <div
              className="fixed top-16 right-6 flex items-center px-2 py-1 rounded-full bg-[#F05252] text-white"
              style={{
                backgroundColor:
                  scanFrameData.confidence > 0.5
                    ? scanFrameData.confidence > 0.9
                      ? "#27C178"
                      : "#FFA609"
                    : "#F05252",
              }}
            >
              <WifiSignal className="shrink-0 mr-1 h-3 w-3" />
              <p className="text-xs font-semibold">
                Signal:&nbsp;
                {scanFrameData.confidence > 0.5
                  ? scanFrameData.confidence > 0.9
                    ? "Good"
                    : "Moderate"
                  : "Poor"}
              </p>
            </div>
            <button
              type="button"
              onClick={() => {
                fingerScan.stopScan(true);
                navigate(-1);
              }}
              className="fixed top-8 left-6 flex items-center text-white text-sm font-semibold"
            >
              <Close className="shrink-0 mr-2 h-6 w-6" />
              <span>Finger Scanning</span>
            </button>
            <div className="fixed bottom-20 inset-x-6 p-4 rounded-lg shadow-sm border border-white/20 bg-white/10">
              <h3 className="text-white font-bold text-lg leading-tight">
                {scanFrameData.type === "scan"
                  ? `${Math.round(
                      ((scanFrameData.timeElapsed - 20000) / 60000) * 100
                    )}% Completed...`
                  : `Calibration in progress...`}
              </h3>
              <h4 className="mt-1.5 text-[#9EA6AB] text-sm leading-snug">
                {getMessage(scanFrameData.type, scanFrameData.timeElapsed)}
              </h4>
            </div>
            <button
              type="button"
              onClick={() => {
                fingerScan.stopScan(true);
                navigate(-1);
              }}
              className="fixed bottom-12 left-6 flex items-center text-[#F05252] text-base font-semibold"
            >
              <span>Cancel Scan</span>
              <RightArrow className="shrink-0 ml-0.5 h-4 w-4" />
            </button>
          </div>
          {fingerScan.isInitializing() && (
            <div className="absolute inset-0 bg-white px-8 py-12 flex flex-col items-center justify-center text-center">
              <LoadingScreen />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FingerScan;
