import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/arrow-left.svg";
import { ReactComponent as SelectPostureBanner } from "../assets/icons/select-posture.svg";
import { ReactComponent as PostureResting } from "../assets/icons/PostureResting.svg";
import { ReactComponent as PostureStanding } from "../assets/icons/PostureStanding.svg";
import { ReactComponent as PostureWalking } from "../assets/icons/PostureWalking.svg";
import { ReactComponent as PostureExercising } from "../assets/icons/PostureExercising.svg";

const SelectPosture = () => {
  const { state } = useLocation();
  const [selectedPosture, setPosture] = useState(state.posture ?? "resting");

  return (
    <section className="min-h-screen px-5 pt-16 pb-32">
      <div className="fixed top-0 left-0 right-0 flex items-center space-x-2 px-4 py-3 border-b border-[#D6D9DA] bg-white text-[#0D212C]">
        <Link
          className="shrink-0 h-6 w-6"
          to="/select-scan-type"
          state={{ ...state, posture: undefined }}
        >
          <BackArrow className="h-full w-full" />
        </Link>
        <h2 className="text-sm font-semibold">Wellness</h2>
      </div>
      <SelectPostureBanner className="h-28 mt-4 mx-auto" />
      <h5 className="mt-6 text-[#0D212C] text-base leading-tight font-semibold">
        Please select the posture you were in immediately prior to the
        assessment:
      </h5>
      <div className="mt-6 space-y-2">
        {[
          {
            icon: <PostureResting className="shrink-0 h-5 w-5" />,
            label: "Resting",
            value: "resting",
          },
          {
            icon: <PostureStanding className="shrink-0 h-5 w-5" />,
            label: "Standing",
            value: "standing",
          },
          {
            icon: <PostureWalking className="shrink-0 h-5 w-5" />,
            label: "After Walking",
            value: "walking",
          },
          {
            icon: <PostureExercising className="shrink-0 h-5 w-5" />,
            label: "After Running",
            value: "exercising",
          },
        ].map((posture) => (
          <button
            key={`posture-${posture.value}`}
            className={`w-full flex items-center justify-between space-x-2 rounded-lg border p-4 ${
              selectedPosture === posture.value
                ? "bg-[#F4FFFD] shadow-none border-[#36C0C9]"
                : "bg-white shadow-sm border-[#D6D9DA]"
            } text-[#0D212C] text-left`}
            onClick={() => setPosture(posture.value)}
          >
            {posture.icon}
            <span className="flex-grow text-xs">{posture.label}</span>
            <div
              className={`flex-shrink-0 relative h-4 w-4 p-0.5 rounded-full ${
                selectedPosture === posture.value
                  ? "border-2 border-[#3ABBC7]"
                  : "border border-[#D6D9DA]"
              } bg-[#F9FAFB]`}
            >
              <div
                className={`h-full w-full rounded-full${
                  selectedPosture === posture.value ? " bg-[#3ABBC7]" : ""
                }`}
              />
            </div>
          </button>
        ))}
      </div>
      <div className="mt-6 p-4 rounded-lg bg-[#FFFDE7] border border-[#FCE484] space-y-1.5">
        <p className="text-xs text-left text-[#6E7A80]">
          Please select the option that best describes your posture before the
          assessment. This information will help us to better understand your
          physical state during the assessment.
        </p>
        <h3 className="text-xs font-semibold text-[#0D212C] mt-4">
          Note:
          <br />
          Your posture should be at resting while doing the scan.
        </h3>
      </div>

      <div className="fixed bottom-0 left-0 right-0 h-[7.5rem] p-5 rounded-t-[20px] shadow-drop bg-white">
        <Link
          className="block w-full px-4 py-3.5 rounded-xl bg-[#3ABBC7] text-white text-center text-sm font-bold"
          to="/scan-instructions"
          state={{ ...state, posture: selectedPosture }}
        >
          Next
        </Link>
      </div>
    </section>
  );
};

export default SelectPosture;
